define('emersonlackey/app', ['exports', 'ember', 'emersonlackey/resolver', 'ember-load-initializers', 'emersonlackey/config/environment'], function (exports, _ember, _emersonlackeyResolver, _emberLoadInitializers, _emersonlackeyConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _emersonlackeyConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _emersonlackeyConfigEnvironment['default'].podModulePrefix,
    Resolver: _emersonlackeyResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _emersonlackeyConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});