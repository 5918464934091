define('emersonlackey/components/twitter-feed', ['exports', 'ember', 'ic-ajax'], function (exports, _ember, _icAjax) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: 'twitterFeed',
    tagName: 'ul',
    tweets: [],

    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _icAjax['default'])('http://104.236.252.229/v1/timeline/emerson_lackey').then(function (tweets) {
        _this.set('tweets', tweets.tweets.slice(0, 5));
      });
    }

  });
});